<template>
  <div class="div-login">
    <v-snackbar
        v-model="notificationsnackbar"
        :timeout="6000"
        :color="notificationcolor"
        :top="true"
        :right="true"
        :vertical="true"
    >
      {{ notificationtext }}
      <v-btn
          color="white"
          text
          @click="notificationsnackbar = false"
      >
        {{ $t("Notification.Schliessen") }}
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
    <span></span>


    <div class="div-login1" v-if="!checkSendEmail">
      <!--      <img class="img-logo-login" :src="logo" alt="Logo"/>-->
      <div></div>
      <label class="label-login">
        <div style="margin-bottom: 10px;">WWW.{{ pDomain.toUpperCase() }}</div>
        {{ $t("Login.AnmeldungzuAccount") }}
      </label>

      <div class="div-set-login" v-if="!showResetPW">
        <v-text-field
            :rules="[rules.required, rules.email]"
            v-model="email"
            :label="$t('Login.Benutzername')"
            outlined
            color="var(--Secondary)"
            @keyup.enter="login"

        />

        <v-text-field
            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show2 ? 'text' : 'password'"
            name="input-10-2"
            v-model="password"
            class="input-group--focused"
            @click:append="show2 = !show2"
            :label="$t('Login.Passwort')"
            outlined
            color="var(--Secondary)"
            @keyup.enter="login"
        />

        <v-btn class="button-login" large color="var(--Secondary)" @click="login">
          {{ $t("Login.Button") }}
        </v-btn>
      </div>

      <div class="div-resetpw-login" v-if="showResetPW">
        Passwort zurücksetzen

        <v-text-field
            :rules="[rules.required, rules.email]"
            v-model="emailResetPW"
            :label="$t('Login.Benutzername')"
            outlined
            color="var(--Secondary)"
            @keyup.enter="resetPW"

        />

        <v-btn class="button-login" large color="var(--Secondary)" @click="resetPW">
          Neues Passwort
        </v-btn>

      </div>

      <v-btn text color="var(--Primary)" @click="showResetPW = !showResetPW">
        {{ !showResetPW ? $t("Login.Passwortvergessen") : "zurück zu Login" }}
      </v-btn>


    </div>
    <div class="div-login1" v-if="checkSendEmail">
      Es wurde eine E-Mail gesendet, bitte prüfen Sie Ihren Posteingang!
    </div>


    <CeeYuuFooter/>


  </div>
</template>

<script>
import logo from "../assets/CeeYuu_Logo_100x100.png";
import axios from "axios";
import {getUser} from "@/util/Auth";
import CeeYuuFooter2 from "@/components/headerfooter/CeeYuuFooter2";
import CeeYuuFooter from "@/components/headerfooter/CeeYuuFooter";
import {MailPWforgotten} from "@/util/mailSend";
import {getUserStatusByEmail} from "@/util/userUtil";

export default {
  name: "Login",

  components: {
    CeeYuuFooter2,
    CeeYuuFooter
  },
  props: [
    'pDomain',
  ],
  data() {
    return {
      logo: logo,
      domain: "",
      user: "",


      notificationsnackbar: false,
      notificationtext: "",
      notificationcolor: "grey",

      checkSendEmail: false,

      show2: false,
      email: "",
      password: "",
      rules: {
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value);
        }
      },
      showResetPW: false,
      emailResetPW: ""
    };
  },
  methods: {
    notification(text, color) {
      this.notificationsnackbar = true;
      this.notificationtext = text;
      this.notificationcolor = color;

    },
    async resetPW() {

      let data = {
        mail: this.emailResetPW,

      };

      //http://admin.efehotels.com/login
      let domain = this.domain.split('admin.')[1];
      data.domain = domain.split('/login')[0];

      data.url = this.url.split("/login")[0];
      try {
        let status = await getUserStatusByEmail(this.emailResetPW);
        if (status === 200) {
          await MailPWforgotten(data);
          this.checkSendEmail = true;
        }
      } catch (e) {

      }


      this.notification("E-Mail wurde versendet.", "green");


    },
    async login() {
//    await this.$router.push('/');


      let apiBaseUrl = process.env.VUE_APP_DB + "/api2/";

      let payload = {
        email: this.email.toLowerCase().trim(),
        password: this.password.trim()
      };

      // this.$router.push("/");//Kann bei aktivem Auth gelöscht werden


      try {
        let res = await axios.post(apiBaseUrl + "auth", payload);


        if (res.status === 200) {
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("nlo", "1");

          if (localStorage.getItem("token"))
            this.user = await getUser(localStorage.getItem("token"));
          if (this.user.rolle !== "hotel") {
            await this.$router.push("/");
          } else {

            await this.$router.push("/hotellist");
          }
        }


        if (res.status === 400) {
          // this.notificationsnackbar = true;
          // this.notificationtext = "E-Mail oder Passwort falsch!";
        }
      } catch (error) {

        this.notificationsnackbar = true;
        this.notificationtext = "E-Mail oder Passwort falsch!";
        this.notificationcolor = "error";
      }
    }
  },
  created() {
    // let url = window.location.hostname;
    // this.domain = url;

    localStorage.removeItem('c-in');
    localStorage.removeItem('c-out');
    localStorage.removeItem('des');
    localStorage.removeItem('desP');
    localStorage.removeItem('desID');
    localStorage.removeItem('groups');
    localStorage.removeItem('gruppe');
    localStorage.removeItem('selectedroom');
    localStorage.removeItem('url');

    this.domain = this.pDomain;


    let url1 = window.location.href;
    this.url = url1;

  }
};
</script>

<style scoped>
@import "../css/Login.css";

</style>
